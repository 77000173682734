<template>
  <skeleton-sales-orders-component v-if="bSkeleton" />
  <div class="content-sales-orders" v-else>
    <filters-sales-orders-component @setDate="setDate" @setSearch="setSearch" @setStatus="setStatus" />
    <div>
      <no-data-text-component-global :iHeight="0" :sTextEmpty="sTextEmpty" v-if="bEmptyTable" />
      <v-data-table v-else v-show="!isMobile" mobile-breakpoint="0" :headers="headers" :disable-sort="true"
        :items="aTable" :loading="bLoadingTable" loading-text="Cargando datos..." :items-per-page="iItemsPerPage"
        :hide-default-footer="true" no-data-text="No hay información disponible para mostrar." :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }" class="global-table">
        <template slot="progress">
          <v-progress-linear color="var(--primary-color-loading-table)" indeterminate></v-progress-linear>
        </template>
        <template v-slot:[`item.sDriverLicensePlate`]="{ item }">
          <span :class="
            item.sDriverLicensePlate == 'Sin información.' ? 'text-data-empty' : ''
          ">
            {{ item.sDriverLicensePlate }}
          </span>
        </template>
        <template v-slot:[`item.accions`]="{ item }">
          <v-btn @click="detailSalesOrder(item)" class="global-btn-table-detail" icon>
            <v-icon> mdi-eye-outline </v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <footer-table-component-global :numPages="numPages" :totalItems="totalItems"
            @changeTable="getEmitSalesOrder" />
        </template>
      </v-data-table>
      <table-mobile-sales-orders-component v-show="isMobile" @changeTable="getEmitSalesOrder"
        @openDetailItem="detailSalesOrder" :aTable="aTable" :headers="headers" :numPages="numPages"
        :totalItems="totalItems" :bChangeSelectedItem="bChangeSelectedItem" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iTab: Number,
    sIdCustomer: String
  },
  data() {
    return {
      emptyData: "Sin información.",

      bSkeleton: true,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      sTextEmpty: "",

      headers: [
        {
          text: "Folio",
          sortable: false,
          value: "sReference",
          class: "global-header-table global-header-name",
          cellClass: "global-body-table global-body-name ",
        },
        {
          text: "Placa del vehículo",
          sortable: false,
          value: "sDriverLicensePlate",
          class: "global-header-table ",
          cellClass: "global-body-table global-body-table ",
        },
        {
          text: "Nombre del transportista",
          sortable: false,
          value: "sDriverFullName",
          class: "global-header-table global-header-account-type",
          cellClass: "global-body-table",
        },
        // {
        //   text: "Moneda acumulado",
        //   sortable: false,
        //   value: "dTotalAmount",
        //   class: "global-header-table global-header-associated-currency",
        //   cellClass: "global-body-table ",
        // },
        {
          text: "Estado",
          sortable: false,
          value: "sStatusName",
          class: "global-header-table global-header-associated-currency",
          cellClass: "global-body-table ",
        },
        {
          text: "Fecha de creación",
          sortable: false,
          value: "tCreatedAt",
          class: "global-header-table global-header-associated-currency",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table  global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      aTable: [],
      isMobile: false,
      bChangeSelectedItem: false,
      tStarDate: "",
      tEndDate: "",
      sSearch: "",
      sStatus: "",
    };
  },
  beforeMount() {
    if (this.iTab === 1) {
      this.setTextEmpty();
      this.getSalesOrder();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 599) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    detailSalesOrder(item) {
      this.$router
        .push(
          `/home/customer-detail/${this.sIdCustomer}/op-detail-customer/${item.sSalesOrderId}`
        )
        .catch(() => { });
    },
    getEmitSalesOrder(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getSalesOrder();
    },
    getSalesOrder() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(
            `${URI}/api/sp/v1/customers/${this.$route.params.id}/sales-orders`,
            {
              params: {
                iPageNumber: this.iCurrentPage,
                iItemsPerPage: this.iItemsPerPage,
                sSearch: this.sSearch,
                sStatusId: this.sStatus,
                tStart: this.tStarDate,
                tEnd: this.tEndDate,
              },
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
            }
          )
            .then((response) => {
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;
              this.aTable = response.data.results.results.map((e) => {
                return {
                  ...e,
                  sStatusName: e.oStatus.sStatusName,
                  sDriverLicensePlate: e.oDriver.sLicensePlate === "" || e.oDriver.sLicensePlate === null ? this.emptyData : e.oDriver.sLicensePlate,
                  sDriverFullName: e.oDriver.sFullName
                };
              });
              this.bLoadingTable = false;
              this.bSkeleton = false;
              this.bEmptyTable = this.aTable.length < 1;

              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },

    setDate(objDate) {
      this.tStarDate = objDate.tStart;
      this.tEndDate = objDate.tEnd;
      this.iCurrentPage = 1;
      this.getSalesOrder();
    },
    setSearch(sSearch) {
      this.sSearch = sSearch;
    },
    setStatus(sStatus) {
      this.sStatus = sStatus;
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen operaciones registradas para mostrar.</p>"
      this.sTextEmpty = sTextemptyTemp;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getSalesOrder();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowProvider() {
      return this.$store.state.bShowProvider;
    },
    bAdminProvider() {
      return this.$store.state.bAdminProvider;
    },
  },
  watch: {
    refreshTable: function () {
      this.getSalesOrder();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getSalesOrder();
    },
    sStatus() {
      this.iCurrentPage = 1;
      this.getSalesOrder();
    },
    iCurrentPage: function () {
      this.getSalesOrder();
    },
    iTab() {
      if (this.iTab === 1) {
        this.$store.commit("setEmptyRawMaterials")

        this.bLoadingTable = true;
        this.aTable = [];
        this.bChangeSelectedItem = !this.bChangeSelectedItem;
        this.$store
          .dispatch("getPermissionsByUserGlobal")
          .then((resp) => {
            this.getSalesOrder();
          })
          .catch((err) => {
            this.Error(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.content-sales-orders {
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 0px 12px 0px 12px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-sales-orders {
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 0px 10px 0px 10px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>