var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bSkeleton)?_c('skeleton-sales-orders-component'):_c('div',{staticClass:"content-sales-orders"},[_c('filters-sales-orders-component',{on:{"setDate":_vm.setDate,"setSearch":_vm.setSearch,"setStatus":_vm.setStatus}}),_c('div',[(_vm.bEmptyTable)?_c('no-data-text-component-global',{attrs:{"iHeight":0,"sTextEmpty":_vm.sTextEmpty}}):_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile),expression:"!isMobile"}],staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"items":_vm.aTable,"loading":_vm.bLoadingTable,"loading-text":"Cargando datos...","items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"no-data-text":"No hay información disponible para mostrar.","footer-props":{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }},scopedSlots:_vm._u([{key:"item.sDriverLicensePlate",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.sDriverLicensePlate == 'Sin información.' ? 'text-data-empty' : ''},[_vm._v(" "+_vm._s(item.sDriverLicensePlate)+" ")])]}},{key:"item.accions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"global-btn-table-detail",attrs:{"icon":""},on:{"click":function($event){return _vm.detailSalesOrder(item)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems},on:{"changeTable":_vm.getEmitSalesOrder}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2),_c('table-mobile-sales-orders-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"aTable":_vm.aTable,"headers":_vm.headers,"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bChangeSelectedItem":_vm.bChangeSelectedItem},on:{"changeTable":_vm.getEmitSalesOrder,"openDetailItem":_vm.detailSalesOrder}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }